.slick-prev {
  display: block;
}

.slick-next {
  display: block;
}

.slick-prev:before {
  content: '' !important;
}

.slick-next:before {
  content: '' !important;
}

.slick-dots button::before {
  display: none;
}
