.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1170px) {
  .container {
    max-width: 1170px;
  }
}

.arrow-down::after {
  position: absolute;
  right: -14px;
  top: 7px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-width: 8px;
  border-style: solid;
  border-color: transparent;
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-top-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.btn-orange {
  display: inline-block;
  border-radius: 25px;
  background-color: #f48121;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  font-size: 17px;
  line-height: 1.5;
  color: rgb(229 229 229);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.btn-orange:hover {
  background-color: #f6670f;
}

.btn-orange:focus:hover {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.accordion-btn {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 0px;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 1rem;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.accordion-btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.black-shape::after {
  position: absolute;
  bottom: -0.25rem;
  display: block;
  height: 68px;
  width: 100%;
  background-image: url('/images/shape-black.png');
  background-size: auto 68px;
  background-position: bottom;
  background-repeat: no-repeat;
  --tw-content: '';
  content: var(--tw-content);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.invisible {
  visibility: hidden;
}

.bg-\[\#f7f7fa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 250 / var(--tw-bg-opacity));
}

.bg-\[\#1b1c1d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 28 29 / var(--tw-bg-opacity));
}

.bg-\[\#1a1c1c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 28 28 / var(--tw-bg-opacity));
}

.bg-\[\#fafafa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-\[\#141415\] {
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 21 / var(--tw-bg-opacity));
}

.bg-\[\#fa6500\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 101 0 / var(--tw-bg-opacity));
}

.bg-inner-banner {
  background-image: url('/images/inner-banner-bg.png');
}

.bg-icon-right {
  background-image: url('/images/icon-right-orange.png');
}

.bg-icon-right-gray {
  background-image: url('/images/icon-right-gray.svg');
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-shape-white {
  background-image: url('/images/shape_white.png');
}

.bg-purple-shape {
  background-image: url('/images/purple-shape-bg.jpg');
}

.bg-key-bg {
  background-image: url('/images/key-based-bg.jpg');
}

.bg-home-header {
  background-image: url('/images/home-header-bg.svg');
}

.bg-home-business {
  background-image: url('/images/personal-business.webp');
}

.download-banner {
  @apply bg-center bg-no-repeat bg-cover text-center z-10 relative text-2xl text-white font-Lato
}

.download-banner h2 {
  @apply mb-0 pb-0
}

.download-banner p {
  @apply max-w-[620px] mx-auto pb-0 mb-[40px]
}

.from-\[\#f95401\] {
  --tw-gradient-from: #f95401;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(249 84 1 / 0));
}

.from-\[\#190b3c\] {
  --tw-gradient-from: #190b3c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(25 11 60 / 0));
}

.from-\[\#1d093d\] {
  --tw-gradient-from: #1d093d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(29 9 61 / 0));
}

.from-\[\#0f0c2b\] {
  --tw-gradient-from: #0f0c2b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(15 12 43 / 0));
}

.from-\[\#1e0a3d\] {
  --tw-gradient-from: #1e0a3d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(30 10 61 / 0));
}

.from-\[\#140e33\] {
  --tw-gradient-from: #140e33;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(20 14 51 / 0));
}

.to-\[\#f8c131\] {
  --tw-gradient-to: #f8c131;
}

.to-\[\#271454\] {
  --tw-gradient-to: #271454;
}

.to-\[\#23134b\] {
  --tw-gradient-to: #23134b;
}

.to-\[\#1e0a3d\] {
  --tw-gradient-to: #1e0a3d;
}

#main-header.fixed-header {
  background: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

#main-header.fixed-header .logo-wrap {
  height: 43px;
}

#main-header.fixed-header a.logo {
  display: none;
}

#main-header.fixed-header a.fixed-menu-logo {
  @apply block
}

#main-header.fixed-header ul li a {
  color: black;
}

#main-header.fixed-header ul li a.btn-orange {
  @apply bg-orange hover:opacity-90
}

#main-header.fixed-header ul li a.active {
  color: #f06725;
}

#main-header.fixed-header ul li a:hover {
  color: #f06725;
}

/* banner css */

.banner-bottom-slider .slider-item img {
  display: inline-block;
}

.banner-bottom-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 27px;
  font-size: 0;
}

.banner-bottom-slider .slick-prev {
  background: url('/images/arrow-left.png') no-repeat center center;
  left: -18%;
}

.banner-bottom-slider .slick-prev:hover {
  background: url('/images/arrow-left-hover.png') no-repeat center center;
}

.banner-bottom-slider .slick-next {
  background: url('/images/arrow-right.png') no-repeat center center;
  right: -18%;
}

.banner-bottom-slider .slick-next:hover {
  background: url('/images/arrow-right-hover.png') no-repeat center center;
}

.banner-bottom-slider .slick-dots {
  margin-top: 20px;
}

.banner-bottom-slider .slick-dots li {
  display: inline-block;
  padding: 0 5px;
}

.banner-bottom-slider .slick-dots li button {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: block;
  font-size: 0;
  width: 7px;
  height: 7px;
}

.banner-bottom-slider .slick-dots li.slick-active button {
  background: white;
}

.support-content .accordion-button:not(.collapsed)::before {
  background: url('/images/icon-up-arrow.png') no-repeat center center;
  right: 0;
  position: absolute;
}

.support-content .accordion-button::before {
  background: url('/images/icon-down-arrow.png') no-repeat center center;
  right: 0;
  position: absolute;
}

.support-content .accordion-body ul li a {
  background: url('/images/gray-circle.png') no-repeat 12px center;
  padding-left: 54px;
}

.support-category .tab-content > .tab-pane {
  display: none;
}

.support-category .tab-content > .active {
  display: block;
}

.related-articles ul li a {
  background: url('/images/related-article-icon.png') no-repeat left 0;
  padding-left: 24px;
}

.promo-banner-fix li {
  @apply leading-[1.2];
}

.intro-banner.downloads-page{
  @apply md:!py-16
}

.before\:bg-digit-bg::before {
  content: var(--tw-content);
  background-image: url('/images/digit-bg.png');
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:bg-icon-right {
  background-image: url('/images/icon-right-orange.png');
}

.group:hover .group-hover\:bg-\[right_center\] {
  background-position: right center;
}

.group:hover .group-hover\:text-\[\#f06520\] {
  --tw-text-opacity: 1;
  color: rgb(240 101 32 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.home-slider {
  position: relative;
}

.home-slider .desktop-slider {
  background: url('/images/slider-desktop-bg.png') no-repeat center top;
  padding: 41px 231px 240px 240px;
}

.home-slider .tablet-slider {
  background: url('/images/slider-tablet-bg.png') no-repeat center top;
  width: 526px;
  height: 367px;
  padding: 14px 37px 8px 37px;
  position: absolute;
  left: 0;
  bottom: 36px;
}

.home-slider .phone-slider {
  background: url('/images/slider-phone-bg.png') no-repeat center top;
  width: 151px;
  height: 302px;
  padding: 9px 8px 8px 9px;
  position: absolute;
  left: 426px;
  bottom: 36px;
}

.home-slider .laptop-slider {
  background: url('/images/slider-laptop-bg.png') no-repeat center top;
  width: 769px;
  height: 443px;
  padding: 32px 95px 50px 95px;
  position: absolute;
  right: 0;
  bottom: 34px;
}

.brand-style {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(244 244 244 / var(--tw-border-opacity));
  padding-top: 22px;
  padding-bottom: 0.75rem;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.brand-style:hover {
  margin-left: -48px;
  margin-right: -48px;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 3px 15px 57px -20px rgba(0, 0, 0, 0.75);
  --tw-shadow-colored: 3px 15px 57px -20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* ul.brand-logos li:nth-child(1) img,
ul.brand-logos li:nth-child(2) img,
ul.brand-logos li:nth-child(4) img,
ul.brand-logos li:nth-child(5) img {
  height: 30px;
}

ul.brand-logos li:nth-child(3) img {
  height: 60px;
}

ul.brand-icons li:nth-child(1) img {
  height: 38px;
}

ul.brand-icons li:nth-child(2) img {
  height: 31px;
}

ul.brand-icons li:nth-child(3) img {
  height: 21px;
} */

/* ul.brand-icons li:nth-child(4) img {
  height: 37px;
} */

.popup-banner {
  background-image: linear-gradient(to right, #00ffb3 0%, #0009ff 100%);
}

.slick-slider * {
  font-display: swap;
}

/*

.article-text-style {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;  
}

.article-text-style p {
  padding-bottom: 20px;
}

.article-text-style h2 {
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  line-height: 1.3em;  
  padding: 20px 0px 10px 0px;
}

.article-text-style ul {
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 10px;  
}

.big-intro-text {
  text-align: center;
  font-family: Lato,sans-serif;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
*/

.download-intro {
  @apply md:pt-[100px] bg-no-repeat bg-top -mt-[72px] pt-[50px] relative z-10 m-0 text-center
}

.download-intro h2 {
  @apply font-Lato font-bold text-3xl md:text-[40px] leading-[1.2em] text-black m-3 p-0 w-full
}

.download-intro .intro-content {
  @apply font-Lato font-normal text-xl text-black max-w-[460px] mx-auto
}

.download-intro .intro-content p {
  @apply font-Lato font-normal text-xl text-black
}

.product-features-wrap .container {
  @apply xl:min-w-[1230px]
}

.product-features-wrap .product-features-item:first-child {
  @apply md:border-r md:border-[#707070]/30 pr-10 lg:pr-[116px] mb-14 md:mb-0
}

.product-features-wrap .product-features-item:last-child {
  @apply md:pl-10 lg:pl-[80px]
}

.product-features-wrap .product-features-item .product-features-intro {
  @apply md:min-h-[42px] leading-[21px]
}

.product-features-wrap .product-features-item .product-features-item-content {
  @apply text-lg font-Lato
}

.product-features-wrap .product-features-item .product-features-item-content a {
  @apply underline hover:no-underline
}

.product-features-wrap .product-features-item .product-features-item-content ul,
.product-features-wrap .product-features-item .product-features-item-content ol {
  @apply font-Lato text-left ml-5 list-decimal space-y-5
}

.product-features-wrap .product-features-item .product-features-item-content ul li,
.product-features-wrap .product-features-item .product-features-item-content ol li {
  @apply font-Lato font-normal text-lg text-[#1F1F1F] list-outside
}

.product-features-wrap .product-features-item .product-features-item-content ul li u,
.product-features-wrap .product-features-item .product-features-item-content ol li u {
  @apply text-[#6652AD] font-bold relative px-2 no-underline
}

.product-features-wrap .product-features-item .product-features-item-content ul li u::after,
.product-features-wrap .product-features-item .product-features-item-content ol li u::after {
  position: absolute;
  content: "*";
  top: 0px;
  left: 0px;
}

.product-features-wrap .product-features-item .product-features-item-content ul li u::before,
.product-features-wrap .product-features-item .product-features-item-content ol li u::before {
  position: absolute;
  content: "*";
  top: 0px;
  right: 0px;
}

.intro-banner.bg-grey {
  @apply bg-[#F6F6F9] py-12 md:py-24 m-0 text-center
}

.intro-banner.bg-grey .intro-title {
  @apply m-0 p-0
}

.intro-banner.bg-grey h2 {
  @apply font-Lato font-bold text-[26px] md:text-[40px] leading-[1.2em] text-black p-0 mb-3 w-full
}

.intro-banner.bg-grey .intro-content {
  @apply font-Lato font-semibold text-lg md:text-xl leading-6 text-black max-w-[550px] mx-auto p-0 m-0
}

.download-app {
  background: #221249;
  background: linear-gradient(225deg, #221249, #180F39);
  position: relative;
}

.download-app::before {
  content: "";
  background-image: url('/images/download-app-bg.png');
  background-position: center;
  background-repeat: no-repeat;  
  max-width: 1176px;
  width: 100%;
  height: 379px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.download-app .container {
  @apply relative z-10
}

.download-app .product-item.multiple-button .bg-white:hover h3 {
  @apply hidden
}

.bottom-text-box a {
  @apply underline
}

.bottom-text-box a:hover {
  @apply no-underline
}

.bottom-text-box h2 {
  @apply font-Lato font-bold text-3xl leading-[1em] p-0 mt-8 mb-2
}

.promo-modal-wrap {
  background-image: url('/images/bg-promo.png');
  @apply bg-no-repeat pl-[140px] py-[20px] lg:py-[48px] lg:min-h-[200px] relative font-Manrope bg-[length:109px_100%] 
}

.promo-modal-content {
  @apply text-[#2F2F2F] text-[26px] font-bold pr-[48px]
}

.promo-modal-content p {
  @apply p-0 m-0 mb-[10px] leading-tight lg:text-[26px] text-[18px] font-bold font-Manrope
}

.promo-modal-content p strong {
  @apply text-[#FF7900] lg:text-[36px] text-[26px]
}

.promo-modal-wrap a {
  @apply outline-none border-none underline pr-[40px] text-[24px] font-bold relative text-[#8264eb]
}

.promo-modal-wrap a::before {
  background-image: url('/images/arrow-popup.svg');
  @apply content-[""] absolute right-0 w-[33px] h-[33px] bg-no-repeat bg-center
}

.promo-modal-wrap a:hover {
  @apply no-underline
}

#headlessui-portal-root {
  @apply relative z-[9999]
}

.custom-list-style ul {
  list-style: disc;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  list-style-position: outside;
  
}
.custom-list-style ul li {
  padding-bottom: 10px;
  list-style-position: outside;
  font-weight: 500;
}

#limited-time {
  background-image: -webkit-gradient(linear, right bottom, left bottom, color-stop(0, #f7056e), color-stop(1, #f37a23));
  background-image: -o-linear-gradient(left, #f7056e 0, #f37a23 100%);
  background-image: -moz-linear-gradient(left, #f7056e 0, #f37a23 100%);
  background-image: -webkit-linear-gradient(left, #f7056e 0, #f37a23 100%);
  background-image: -ms-linear-gradient(left, #f7056e 0, #f37a23 100%);
  background-image: linear-gradient(to left, #f7056e 0, #f37a23 100%);
  text-align: center;
  padding: 14px 20px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #fff;
}
#limited-time h2 {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 38px;
  color: #fff;
  margin: 0 0 0 0;
  padding: 0;
}

#limited-time a {
  color: #fff;
  border: 1px solid rgb(255 255 255 / 0.7);
  border-radius: 4px;
  padding: 4px 20px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
}
#limited-time p {
  margin: 0 0 1em;
  padding: 0;
}
#limited-time p:last-child {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  #limited-time {
    font-size: 16px;
  }
  #limited-time h2 {
    font-size: 20px;
  }
}

#promotional-banner {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #088dfa), color-stop(1, #ec0b69));
  background-image: -o-linear-gradient(right, #088dfa 0, #ec0b69 100%);
  background-image: -moz-linear-gradient(right, #088dfa 0, #ec0b69 100%);
  background-image: -webkit-linear-gradient(right, #088dfa 0, #ec0b69 100%);
  background-image: -ms-linear-gradient(right, #088dfa 0, #ec0b69 100%);
  background-image: linear-gradient(to right, #088dfa 0, #ec0b69 100%);
  text-align: center;
  padding: 15px 20px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #fff;
}
#promotional-banner h2 {
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 38px;
  color: #fff;
  margin: 0 0 10px 0;
  padding: 0;
}
#promotional-banner a {
  color: #fff;
  border: 1px solid rgb(255 255 255 / 0.7);
  border-radius: 4px;
  padding: 4px 20px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
}
#promotional-banner p {
  margin: 0 0 1em;
  padding: 0;
}
#promotional-banner p:last-child {
  margin: 0;
}

.product-variant-subtitle p {
  padding-bottom: 0
}

.top-sticky {
  background: linear-gradient(90deg, #8F59FF 0.6%, #2E0387 99.99%);
  position: relative;
}

.top-sticky::before {
  background: url("/images/black-friday-bg.png") repeat left top;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.4;  
}

.promo-modal-wrap a:focus,
.promo-modal-wrap a:focus-visible {
  border: none;
  outline: none;
}

.subscribe-form input[type='email'] {
  @apply bg-white w-full border-2 border-[#B19BFF] h-10 pl-5 pr-[40px] rounded-3xl outline-none shadow-none font-normal text-sm text-black;
}

.subscribe-form input::placeholder {
  @apply text-[#848484] text-sm font-Manrope text-[14px] font-normal
}

.subscribe-form input[type='email']:focus {
  @apply bg-white
}

.subscribe-form button {
  @apply bg-[url('images/subscribe-btn.svg')] bg-no-repeat bg-center rounded-3xl border-none absolute md:h-[40px] bg-[length:25px_25px] text-[0px] w-[25px] h-[25px]
}

.copyright .container {
  border-top: 1px solid #CDCDCD;
}

.subscribe-form .inProgress {
  @apply bg-[#E0D9FF]
}

.faq-section details summary::marker {
  display: none;
}

.info {
  @apply bg-[url('images/sme-info.svg')] bg-no-repeat bg-left bg-[length:14px_14px] pl-5 text-[13px] leading-[22px] block
}

.link-icon {
  @apply border-[#F48121] border-b-2 inline-block hover:opacity-90 text-[#F48121] text-sm lg:text-[18px] leading-[28px] font-normal mt-5 relative before:absolute before:content-[''] before:bg-[url('images/link-arrow.svg')] before:bg-no-repeat before:bg-right before:bg-[length:11px_11px] before:-right-6 before:w-3 before:h-3 before:top-[10px]
}

.TextWithImage-section .TextWithImage-item {
  @apply flex-col-reverse flex md:flex-row mb-10 lg:mb-28 gap-16 lg:gap-44 text-[#001064] font-Manrope text-base leading-[28px] items-center
}

.TextWithImage-section .TextWithImage-item:nth-child(2) {
  @apply flex-col md:flex-row mb-0
}

.TextWithImage-item a {
  @apply text-[#F48121]
}

.TextWithImage-item img {
  @apply rounded-[35px]
}

.hero .btn-orange {
  @apply bg-[#F48121] font-Manrope text-base leading-6 font-bold rounded-[36px] py-2.5 px-7 min-w-[138px] border-2 border-[#F48121] text-white
}

.hero .btn-orange:hover {
  @apply bg-transparent
}

.btn-white {
  @apply bg-white font-Manrope text-base leading-6 font-bold rounded-[36px] py-2.5 px-7 min-w-[138px] border-2 border-white text-orange
}

.btn-white:hover {
  @apply bg-transparent
}

.btn-light {
  @apply bg-transparent font-Manrope text-base text-orange leading-6 font-bold rounded-[36px] py-2.5 px-7 min-w-[138px] border-2 border-orange
}

.btn-light:hover {
  @apply bg-orange text-white
}

.btn-link {
  @apply bg-transparent font-Manrope text-base text-orange leading-6 font-normal underline relative pr-5 inline-block
  after:content-[""] after:bg-[url('images/ic-orange-arrow.svg')] after:bg-left bg-no-repeat after:w-[11px] after:h-2 after:absolute after:right-0 after:top-[14px] after:-translate-y-1/2
}

.btn-link:hover {
  @apply no-underline
}

.arrow-down:after {
  position: absolute;
  right: -14px;
  top: 7px;
  border-bottom-style: solid;
  border-left: 4px solid transparent;
  border-bottom-color: transparent;
  border-right: 4px solid transparent;
  content: "";
  border-top: 8px solid transparent;
  border-top-color: #fff;
}

header .btn-orange {
  @apply py-2
}

.hero {
  background: linear-gradient(335.11deg, #8846E2 15.16%, #1D1263 85.07%);
}

.hero::before {
  background: url('images/hero-left-bg.svg') no-repeat left bottom;
  content: "";
  width: 719px;
  height: 722px;
  position: absolute;
  left: 0;
  top: 210px;
}

.hero::after {
  background: url('images/hero-right-bg.svg') no-repeat left bottom;
  content: "";
  width: 797px;
  height: 811px;
  position: absolute;
  right: 0;
  top: 86px;
}

.hero h1 {
  @apply font-normal mb-5
}

.hero h1 strong, .hero h1 b {
  @apply font-extrabold
}

.hero h3 {
  @apply text-[22px] leading-[32px]
}

.hero a.btn-orange {
  @apply py-[13px] inline-block mt-10 md:mt-3 mb-6
}

.anchorblock .brand-row {
  @apply flex flex-wrap items-center gap-3
}

.brand-row .brand-item {
  transition: margin .2s;
  @apply relative cursor-pointer rounded-full -mr-5
}

.brand-row .brand-item:hover {
  @apply ml-1 relative z-50
}

.brand-row .brand-item .tooltip {
  @apply bg-white text-black shadow-lg -top-12 transition-all absolute z-10 px-3 py-4 rounded-lg invisible 
}

.brand-row .brand-item:hover .tooltip {
  @apply visible
}

.bounce-in {
  animation: bounce-in 2s ease infinite;
}

.brand-row .brand-item:nth-child(1) .tooltip {
  left: -40px;
  top: -56px;
  rotate: 7deg;
  z-index: 50;
}

.brand-row .brand-item:nth-child(2) .tooltip {
  min-width: 100px;
  left: -50px;
  rotate: -4deg;
  top: -66px;
  z-index: 100;
}

.brand-row .brand-item:nth-child(3) .tooltip {
  min-width: 100px;
  left: -50px;
  rotate: 4deg;
  top: -54px;
  z-index: 150;
}

.brand-row .brand-item:nth-child(4) .tooltip {
  min-width: 100px;
  left: -50px;
  rotate: 0deg;
  top: -56px;
  z-index: 200;
}

.brand-row .brand-item:nth-child(5) .tooltip {
  min-width: 100px;
  left: -26px;
  rotate: 14deg;
  top: -54px;
  z-index: 250;
}

@keyframes bounce-in {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% { transform: scale(.9); }
  100% { transform: scale(1); }
}

.app_rating::before {
  @apply content-[""] w-px h-8 bg-white/30 absolute -left-[50px] top-9
}

.app_rating ul span {
  @apply bg-black text-xs font-semibold rounded-sm py-0 px-1.5 leading-4 relative ml-1 
  before:content-[""] before:top-1/2 before:-left-2.5 before:absolute
}

.app_rating ul span::before {
  transform: translate(.4em, -50%);
  border: 6px solid transparent;
  z-index: 101;
  line-height: 1;
  border-left-width: 0;
  border-right-color: #000;
}

.slick-slider {
  opacity: 0;
}

.slick-slider.slick-initialized {
  opacity: 1;
}

.user-slider .slick-dots {
  @apply -bottom-12 
}

.user-slider .slick-track {
  @apply flex gap-8
}

.user-slider .user-slider-item {
  @apply shadow-[0_5px_10px_0px_rgba(0,0,0,0.1)] pt-12 pb-8 px-8 rounded-[35px] my-5 !flex flex-col items-start justify-between border border-[#EEEEEE] min-h-[280px]
}

.user-slider .user-slider-item span {
  @apply line-clamp-4
}

.slick-slide {
  float: inherit !important;
}

.slick-list {
  height: 100% !important;
}

.user-slider .slick-dots {
  @apply flex justify-center gap-4
}

.user-slider .slick-dots button {
  @apply w-2 h-2 rounded-full text-[0px] bg-[#1E1E1E]/50
}

.user-slider .slick-dots .slick-active button {
  @apply bg-[#1E1E1E]
}

.app-list a {
  @apply flex justify-center
}

.half-text-image .text-box p a {
  @apply text-orange underline
}

.half-text-image .text-box p a:hover {
  @apply no-underline
}

.half-text-image .img-box img {
  @apply rounded-[35px]
}

.encrypted-service h2 {
  @apply mb-10 md:mb-20 font-normal
}

.encrypted-service .service-item:nth-child(odd) {
  @apply flex-row-reverse md:mt-32 
}

.encrypted-service .service-item .text-block {
  @apply md:px-10
}

.encrypted-service .service-item:nth-child(odd) .img-block {
  @apply px-20
}

.encrypted-service .service-item .text-block h3 {
  @apply relative
}

.encrypted-service .service-item .text-block-item:nth-child(1) h3::before {
  @apply bg-[url('images/ic-play.svg')] content-[""] w-[18px] h-9 absolute -left-[40px] top-1 bg-[#181436] bg-no-repeat z-20
}

.encrypted-service .service-item .text-block-item:nth-child(2) h3::before {
  @apply bg-[url('images/ic-email.svg')] content-[""] w-[22px] h-9 absolute -left-[42px] top-1 bg-[#181436] bg-no-repeat z-20
}

.encrypted-service .service-item .text-block-item:nth-child(3) h3::before {
  @apply bg-[url('images/ic-device.svg')] content-[""] w-6 h-9 absolute -left-[42px] top-1 bg-[#181436] bg-no-repeat z-20
}

.encrypted-service .service-item:nth-child(odd) .text-block-item:nth-child(1) h3::before {
  @apply bg-[url('images/ic-bell.svg')] content-[""] w-[22px] h-9 absolute -left-[40px] top-1 bg-[#181436] bg-no-repeat z-20
}

.encrypted-service .service-item:nth-child(odd) .text-block-item:nth-child(2) h3::before {
  @apply bg-[url('images/ic-email-check.svg')] content-[""] w-[22px] h-9 absolute -left-[40px] top-1 bg-[#181436] bg-no-repeat z-20
}

.encrypted-service .service-item:nth-child(odd) .text-block-item:nth-child(3) h3::before {
  @apply bg-[url('images/ic-encryption.svg')] content-[""] w-[22px] h-9 absolute -left-[40px] top-1 bg-[#181436] bg-no-repeat z-20
}

.encrypted-service .service-item .text-block-item:hover h3 a {
  @apply text-[#9280FF]
}

.encrypted-service .service-item .text-block h3 a {
  @apply font-Manrope font-bold text-[22px] leading-[30px] text-white no-underline 
}

.encrypted-service .service-item .text-block h3 a:hover {
  @apply text-[#9280FF]
}

.encrypted-service .service-item .text-block p {
  @apply m-0 p-0
}

.encrypted-service .service-item .text-block a {
  @apply text-orange underline
}

.encrypted-service .service-item .text-block a:hover {
  @apply no-underline
}

.encrypted-service .service-item .text-block-item+.text-block-item {
  @apply mt-6
}

.encrypted-service .service-item .text-block-item:hover {
  @apply before:border-[#9280FF]
}

.inner-banner-small {
  @apply pb-3 md:pb-[40px] mt-0
}

.inner-banner-small h1 {
  @apply mt-10
}

.liner-gradient::after {
  content: "";
  width: 100%;
  height: 36px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-end-end-radius: 35px;
  border-end-start-radius: 35px;
  background: rgb(228,199,228);
  background: -moz-linear-gradient(90deg, rgba(228,199,228,1) 0%, rgba(146,128,255,1) 50%);
  background: -webkit-linear-gradient(90deg, rgba(228,199,228,1) 0%, rgba(146,128,255,1) 50%);
  background: linear-gradient(90deg, rgba(228,199,228,1) 0%, rgba(146,128,255,1) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e4c7e4",endColorstr="#9280ff",GradientType=1);
}

.contact-support-form select,
.contact-support-form input,
.contact-support-form textarea {
  @apply bg-white w-full border-2 border-[#B19BFF] pl-5 pr-[40px] rounded-xl outline-none shadow-none font-normal text-sm text-black
}

.contact-support-form textarea {
  @apply py-2
}

@media (min-width: 768px) and (max-width: 980px) {
  .home-slider .desktop-slider {
    background-size: auto 455px;
    height: 455px;
    padding: 24px 103px 137px 108px;
  }

  .home-slider .tablet-slider {
    background-size: auto 205px;
    padding: 8px 20px 8px 22px;
    width: 295px;
    height: 205px;
    bottom: 23px;
  }

  .home-slider .phone-slider {
    background-size: auto 170px;
    padding: 5px 5px 6px 5px;
    width: 85px;
    height: 170px;
    left: 225px;
    bottom: 24px;
  }

  .home-slider .laptop-slider {
    background-size: auto 230px;
    padding: 17px 50px 27px 49px;
    width: 400px;
    height: 230px;
    bottom: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home-slider .desktop-slider {
    background-size: auto 650px;
    height: 650px;
    padding: 34px 101px 195px 109px;
  }

  .home-slider .tablet-slider {
    background-size: auto 270px;
    padding: 10px 27px 9px 28px;
    width: 387px;
    height: 270px;
    bottom: 33px;
  }

  .home-slider .phone-slider {
    background-size: auto 220px;
    padding: 7px 7px 6px 7px;
    width: 110px;
    height: 220px;
    left: 314px;
    bottom: 33px;
  }

  .home-slider .laptop-slider {
    background-size: auto 300px;
    padding: 22px 64px 34px 64px;
    width: 521px;
    height: 300px;
    bottom: 34px;
  }
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .home-slider .desktop-slider {
    height: 796px;
    padding: 41px 105px 240px 115px;
  }

  .home-slider .tablet-slider {
    background-size: auto 330px;
    padding: 12px 33px 13px 34px;
    width: 473px;
    height: 330px;
  }

  .home-slider .phone-slider {
    background-size: auto 280px;
    width: 140px;
    height: 280px;
    left: 384px;
  }

  .home-slider .laptop-slider {
    background-size: 570px 328px;
    padding: 24px 70px 36px 71px;
    width: 570px;
    height: 328px;
  }
}

@media (min-width: 1800px) {
  .home-slider {
    width: 1390px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1024px) {
  .banner-bottom-slider .slick-prev {
    left: -100px;
  }

  .banner-bottom-slider .slick-next {
    right: -100px;
  }
  .slick-slider::before {
    @apply h-full absolute w-[30%] top-0 left-0 z-10 content-[""] bg-gradient-to-r from-white from-20% to-transparent to-80%
  }
  
  .slick-slider::after {
    @apply h-full absolute w-[30%] top-0 right-0 z-10 content-[""] bg-gradient-to-r from-transparent from-20% to-white to-80%
  }
}

@media (min-width: 768px) {
  .banner-bottom-slider .slick-prev {
    left: -6%;
  }

  .banner-bottom-slider .slick-next {
    right: -6%;
  }

  .home-email-ac::after {
    background: url('/images/home-sahape-01.png') no-repeat center top;
    background-size: auto 156px;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 156px;
    display: block;
  }

  .home-price-download::before {
    background: url('/images/home-sahape-02.png') no-repeat center top;
    background-size: auto 102px;
    position: absolute;
    content: '';
    top: -2px;
    left: 0;
    right: 0;
    height: 102px;
    display: block;
  }

  .home-price-download::after {
    background: url('/images/home-sahape-03.png') no-repeat center bottom;
    background-size: auto 95px;
    position: absolute;
    content: '';
    bottom: -2px;
    left: 0;
    right: 0;
    height: 95px;
    display: block;
  }
}

@media (max-width: 767px) {
  .liner-gradient::after {
    @apply w-auto
  }
  br {
    display: none;
  }
  #promotional-banner {
    font-size: 16px;
  }
  #promotional-banner h2 {
    font-size: 20px;
  }

  .home-slider .desktop-slider {
    background-size: 460px 263px;
    width: 460px;
    height: 263px;
    padding: 13px 76px 79px 80px;
  }

  .home-slider .tablet-slider {
    background-size: auto 263px;
    width: 460px;
    height: 263px;
    padding: 10px 68px 10px 69px;
    top: 0px;
    bottom: inherit;
  }

  .home-slider .phone-slider {
    background-size: auto 218px;
    width: 116px;
    height: 218px;
    padding: 7px 10px 7px 10px;
    bottom: 0px;
    left: inherit;
    right: 32px;
  }

  .home-slider .laptop-slider {
    background-size: auto 130px;
    width: 227px;
    height: 130px;
    padding: 9px 28px 13px 29px;
    bottom: 15px;
  }
}

@media (max-width: 480px) {
  .info {
    background-position: left 5px;
  }
  .home-slider .desktop-slider {
    background-size: auto 173px;
    width: 302px;
    height: 173px;
    padding: 9px 50px 52px 52px;
  }

  .home-slider .tablet-slider {
    background-size: auto 173px;
    width: 320px;
    height: 173px;
    padding: 6px 54px 6px 54px;
    top: 0px;
    bottom: inherit;
  }

  .home-slider .phone-slider {
    background-size: auto 124px;
    width: 62px;
    height: 124px;
    padding: 4px;
    bottom: 0px;
    left: inherit;
    right: 32px;
  }

  .home-slider .laptop-slider {
    background-size: auto 81px;
    width: 140px;
    height: 81px;
    padding: 6px 17px 9px 17px;
    bottom: 9px;
  }
}

@media (max-width: 320px) {
  .hero-bottom {
    @apply grid-cols-1
  }
}