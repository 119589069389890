@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth
}

body {
  @apply font-Manrope
}

body section {
  @apply overflow-x-hidden;
}

@layer utilities {
  a {
    @apply no-underline transition duration-300
  }

  a:hover {
    @apply no-underline
  }

  p {
    @apply pb-[1em]
  }

  h1 {
    @apply font-Manrope font-bold text-[40px] leading-[1.35em]
  }

  h2 {
    @apply font-Manrope font-bold text-[32px] leading-[1.5em]
  }

  h3 {
    @apply font-Manrope font-bold
  }

  h4 {
    @apply font-Manrope font-bold
  }

  h1, h2, h3, h4, h5, h6 {
    @apply mb-2.5
  }

  ul,ol {
    @apply list-none m-0 p-0
  }
  .custom-prose p {
    font-weight: lighter;
  }

  .prose a {
    color: rgb(240, 103, 37);
  }

  .custom-prose {
    font-weight: bold;
  }

  .bg-purple-shape {
    background-image: url('/images/purple-shape-bg.jpeg');
  }
  .bg-shape-white {
    background-image: url('/images/shape_white.png');
  }
  .blog-prose ul {
    @apply list-disc;
  }

  * {
    @apply list-inside;
  }

  .faq-prose ul {
    @apply list-disc list-inside;
  }

  .faq-prose a {
    color: rgb(240, 103, 37);
  }

  .support-prose * {
    @apply my-5;
  }

  .support-prose img {
    @apply max-w-full;
  }

  .support-prose a {
    @apply text-[#f06725] hover:underline;
  }

  .support-prose h2,
  h3 {
    @apply text-xl font-semibold;
  }

  .support-prose h1 {
    @apply text-2xl lg:text-3xl font-bold;
  }

  .async-hide {
    opacity: 0 !important;
  }
}

@font-face {
  font-family: 'Manrope';
  src: url('fonts/Manrope-SemiBold.woff2') format('woff2'),
      url('fonts/Manrope-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('fonts/Manrope-Medium.woff2') format('woff2'),
      url('fonts/Manrope-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('fonts/Manrope-Bold.woff2') format('woff2'),
      url('fonts/Manrope-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('fonts/Manrope-ExtraBold.woff2') format('woff2'),
      url('fonts/Manrope-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('fonts/Manrope-Light.woff2') format('woff2'),
      url('fonts/Manrope-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('fonts/Manrope-Regular.woff2') format('woff2'),
      url('fonts/Manrope-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('fonts/Manrope-ExtraLight.woff2') format('woff2'),
      url('fonts/Manrope-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verdana Regular';
  src: url('fonts/Verdana.woff2') format('woff2'), 
        url('fonts/Verdana.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('fonts/Gotham-Bold.woff2') format('woff2'),
      url('fonts/Gotham-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Gotham-Bold.woff2') format('woff2'),
      url('fonts/Gotham-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Light.woff2') format('woff2'),
      url('fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-BlackItalic.woff2') format('woff2'),
      url('fonts/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Italic.woff2') format('woff2'),
      url('fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.woff2') format('woff2'),
      url('fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Black.woff2') format('woff2'),
      url('fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-BoldItalic.woff2') format('woff2'),
      url('fonts/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('fonts/Lato-HairlineItalic.woff2') format('woff2'),
      url('fonts/Lato-HairlineItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-LightItalic.woff2') format('woff2'),
      url('fonts/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.woff2') format('woff2'),
      url('fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('fonts/Lato-Hairline.woff2') format('woff2'),
      url('fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body a {
  color: #f06725;
}